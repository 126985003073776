import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Landing from './landing';
import PrivacyPolicy from './components/tos/privacy-policy/privacy-policy';
import TermsOfUse from './components/tos/terms-of-use/terms-of-use';
import reportWebVitals from './reportWebVitals';
import {
  HashRouter,
  Route,
  Routes,
} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/tos/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/tos/terms-of-use" element={<TermsOfUse />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
